import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuard } from './_helpers';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard/default', //'dashboard/default',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(m => m.DashboardModule)
      },     
      {
        path: 'system',
        loadChildren: () => import('./system/system.module').then(m => m.SystemModule)
      },
      {
        path: 'danhmuc',
        loadChildren: () => import('./quanlydanhmuc/danhmuc.module').then(m => m.DanhmucModule)
      }
      ,
      {
        path: 'chamcong',
        loadChildren: () => import('./quanlychamcong/chamcong.module').then(m => m.ChamcongModule)
      }
      ,
      {
        path: 'tuyendung',
        loadChildren: () => import('./quanlytuyendung/tuyendung.module').then(m => m.TuyendungModule)
      }
      ,
      {
        path: 'giamsat',
        loadChildren: () => import('./giamsatdulieu/giamsat.module').then(m => m.GiamsatModule)
      }
      
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      {
        path: 'changepw',
        loadChildren: () => import('./theme/layout/admin/nav-bar/pw-change/pw-change.module').then(m => m.PwChangeModule)
      },
      {
        path: 'personalinfor',
        loadChildren: () => import('./theme/layout/admin/nav-bar/personalinfor/personalinfor.module').then(m => m.PersonalinforModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
